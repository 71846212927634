import { useQuery } from 'react-query';

import { fetchStarship } from 'Core/api/starships';

const getStarshipById = async starshipId => {
  const { data } = await fetchStarship({ id: starshipId });
  return data;
};

const useStarship = starshipId => useQuery(
  ['starships', starshipId],
  () => getStarshipById(starshipId)
);

export default useStarship;
