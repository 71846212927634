import React from 'react';
import { node } from 'prop-types';

import Content from './Content';

function Footer({ children, ...props }) {
  return (
    <Content component="footer" {...props}>
      {children}
    </Content>
  );
}

Footer.propTypes = {
  children: node
};

export default Footer;
