import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';

import usePeople from 'Hooks/usePeople';
import { useQuestContext } from 'Providers/quest';
import SearchInput from 'Components/SearchInput';
import Button from 'Components/Button';
import Pagination from 'Components/Pagination';
import EmptyState from 'Components/EmptyState';
import Popover from 'Components/Popover';
import Card, { Footer } from 'Components/Card';

import styles from './styles.module.css';

function CharacterPicker({ onClose = () => {} }) {
  const {
    status,
    data,
    error,
    page,
    setPage,
    maxPage,
    setSearchTerm
  } = usePeople();

  const {
    invertedIndex,
    addCrewMember,
    addPassenger,
    removeCrewMember,
    removePassenger,
    isCrewFull,
    isPassengersFull
  } = useQuestContext();

  const characters = useMemo(
    () => data?.results ? data.results.map(c => ({
        ...c,
        isCrew: invertedIndex.crew[c.id],
        isPassenger: invertedIndex.passengers[c.id],
        isOnShip: invertedIndex.crew[c.id] || invertedIndex.passengers[c.id]
      })) : [],
    [data, invertedIndex]
  );

  const onSearch = useCallback(
    e => setSearchTerm(e.target.value),
    [setSearchTerm],
  );

  const hasResults = useMemo(
    () => characters.length > 0,
    [characters]
  );

  const shouldPaginate = useMemo(
    () => characters.length === 10 || page !== 1,
    [page, characters]
  );

  if (status === 'loading') {
    return 'loading...';
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className={styles.characterPicker}>
      <div className={styles.header}>
        <SearchInput placeholder="Search by name..." onChange={onSearch} />
        <Button onClick={onClose} variant="sm">
          Close
        </Button>
      </div>
      {hasResults && (
        <>
          <div className={styles.characters}>
            {characters.map(character => {
              const { name, id, isOnShip, isCrew, isPassenger } = character;
              return (
                <Card key={id} className={styles.character}>
                  <img
                    src={`./characters/${id}.jpg`}
                    alt={name}
                    className={classnames(styles.photo, { [styles.disabled]: isOnShip })}
                  />
                  <Footer className={styles.footer}>
                    <div>{name}</div>
                    {!isOnShip && (
                      <Popover
                        title={(
                          <div className={styles.buttons}>
                            <Button
                              onClick={() => addCrewMember(character)}
                              disabled={isCrewFull}
                              variant="sm"
                            >
                              crew
                            </Button>
                            <Button
                              onClick={() => addPassenger(character)}
                              disabled={isPassengersFull}
                              variant="sm"
                            >
                              passengers
                            </Button>
                          </div>
                        )}
                      >
                        <div className={styles.button}>
                          Add to...
                        </div>
                      </Popover>
                    )}
                    {isCrew && (
                      <div className={styles.button} onClick={() => removeCrewMember(id)}>
                        Remove...
                      </div>
                    )}
                    {isPassenger && (
                      <div className={styles.button} onClick={() => removePassenger(id)}>
                        Remove...
                      </div>
                    )}
                  </Footer>
                </Card>
              );
            })}
          </div>

          {shouldPaginate && (
            <Pagination page={page} setPage={setPage} maxPage={maxPage} />
          )}
        </>
      )}
      {!hasResults && (
        <EmptyState />
      )}
    </div>
  );
}

export default CharacterPicker;
