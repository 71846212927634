import React, { memo } from 'react';
import { string, func } from 'prop-types';

import Input from 'Components/Input';

import styles from './styles.module.css';

function SearchInput({
  value,
  onChange,
  placeholder,
  autoComplete = ''
}) {
  return (
    <Input
      name="search"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={styles.searchInput}
    />
  );
}

SearchInput.propTypes = {
  value: string,
  onChange: func,
  placeholder: string,
  autoComplete: string
};

export default memo(SearchInput);
