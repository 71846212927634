import React, {
  memo,
  useState,
  useCallback,
  useMemo,
  Children,
  isValidElement,
  cloneElement
} from 'react';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import { string, node, array } from 'prop-types';

import { pick } from 'Helpers';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import usePortal from 'Hooks/usePortal';

import styles from './styles.module.css';

function Popover({
  custom,
  title,
  placement = 'bottom',
  triggers = ['onClick'],
  className,
  children
}) {
  const [refElement, setRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(
    refElement,
    popperElement,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement, padding: 20 } },
        { name: 'offset', options: { offset: [0, 15] } }
      ],
      placement
    }
  );

  const [isVisible, setIsVisible] = useState(false);
  const handleShow = useCallback(() => setIsVisible(isShown => !isShown), []);
  const handleHide = useCallback(() => setIsVisible(false), []);

  const eventHandlers = useMemo(() =>
    pick({
      onClick: handleShow,
      onMouseOver: handleShow,
      onMouseLeave: handleHide,
      onFocus: handleShow,
      onBlur: handleHide
    }, triggers),
    [triggers, handleShow, handleHide]
  );

  useOnClickOutside(popperElement, handleHide);

  const innerNodes = useMemo(
    () =>
      Children.count(children) === 1 && !isValidElement(children) ?
        <span>{children}</span>
       :
        children
      ,
    [children]
  );

  const element = useMemo(
    () =>
      Children.map(
        innerNodes,
        child =>
          child &&
          cloneElement(child, {
            ...child.props,
            ...eventHandlers,
            ref: setRefElement
          })
      ),
    [innerNodes, eventHandlers]
  );

  const portal = usePortal('popover');

  return (
    <>
      {element}
      {isVisible && portal(
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          className={styles.popper}
          {...attributes.popper}
          role="tooltip"
        >
          {title &&
            <div className={classnames(styles.tooltip, className)}>
              {title}
              <div
                ref={setArrowElement}
                style={popperStyles.arrow}
                className={styles.arrow}
              />
            </div>
          }
        </div>
      )}
    </>
  );
}

Popover.propTypes = {
  title: node,
  triggers: array,
  placement: string,
  className: string,
  children: node
};

export default memo(Popover);
