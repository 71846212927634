import React, { useMemo } from 'react';
import classnames from 'classnames';

import { useQuestContext } from 'Providers/quest';
import Card, { Footer } from 'Components/Card';
import EmptySlot from './EmptySlot';

import styles from './styles.module.css';

const generatePlaceholders = (length, max) => {
  const placeholders = [];

  for (let i = 0; i < max - length; i++) {
    placeholders.push({ id: i })
  }

  return placeholders;
};

function Squad() {
  const {
    state: { crew, passengers },
    maxCrewMembers,
    maxPassengers
  } = useQuestContext();

  const crewPlaceholders = useMemo(
    () => generatePlaceholders(crew.length, maxCrewMembers),
    [crew, maxCrewMembers]
  );

  const passengersPlaceholders = useMemo(
    () => generatePlaceholders(passengers.length, maxPassengers),
    [passengers, maxPassengers]
  );

  return (
    <div className={styles.squad}>
      Crew
      <div className={classnames(styles.crew, styles.listGrid)}>
        {crew.map(({ id, name, gender, birthYear }) => (
          <Card key={id} className={styles.crewMember} fullHeight>
            <img src={`./characters/${id}.jpg`} alt={name} className={styles.photo} />
            <Footer>
              <div>{name}</div>
              <div>{gender}</div>
              <div>{birthYear}</div>
            </Footer>
          </Card>
        ))}
        {crewPlaceholders.map(({ id }) => (
          <EmptySlot key={id} />
        ))}
      </div>

      Passengers
      <div className={classnames(styles.passengers, styles.listGrid)}>
        {passengers.map(({ id, name, gender, birthYear }) => (
          <Card key={id} className={styles.crewMember} fullHeight>
            <img src={`./characters/${id}.jpg`} alt={name} className={styles.photo} />
            <Footer>
              <div>{name}</div>
              <div>{gender}</div>
              <div>{birthYear}</div>
            </Footer>
          </Card>
        ))}
        {passengersPlaceholders.map(({ id }) => (
          <EmptySlot key={id} />
        ))}
      </div>
    </div>
  );
}

export default Squad;
