import produce from 'immer';

import { createStore } from 'Core/store';

const initialState = {
  starship: { crew: 0, passengers: 0 },
  crew: [],
  passengers: []
};

export const ACTIONS = {
  SET_STARSHIP: 'setStarship',
  ADD_CREW_MEMBER: 'addCrewMember',
  ADD_PASSENGER: 'addPassenger',
  REMOVE_CREW_MEMBER: 'removeCrewMember',
  REMOVE_PASSENGER: 'removePassenger',
  RESET_QUEST: 'resetQuest'
};

const getMaxAllowed = (starship, squadType) =>
  Number.parseInt(starship[squadType], 10);

const addCharacterTo = squadType =>
  (state, character) => produce(state, newState => {
    const maxAllowed = getMaxAllowed(newState.starship, squadType);

    if (newState[squadType].length < maxAllowed) {
      newState[squadType].push(character);
    }
  });

const removeCharacterFrom = squadType =>
  (state, characterId) => produce(state, newState => {
    const characterIndex = newState[squadType].findIndex(
      ({ id }) => id === characterId
    );

    if (characterIndex !== -1) {
      newState[squadType].splice(characterIndex, 1);
    }
  });

const reducers = {
  [ACTIONS.SET_STARSHIP]: (state, starship) =>
    produce(state, newState => {
      newState.starship = starship;
    }),
  [ACTIONS.ADD_CREW_MEMBER]: addCharacterTo('crew'),
  [ACTIONS.REMOVE_CREW_MEMBER]: removeCharacterFrom('crew'),
  [ACTIONS.ADD_PASSENGER]: addCharacterTo('passengers'),
  [ACTIONS.REMOVE_PASSENGER]: removeCharacterFrom('passengers'),
  [ACTIONS.RESET_QUEST]: ({ starship }) => ({ ...initialState, starship })
};

// "getters" somewhat inspired by Vuex and Redux selectors

const maxCrewMembers = ({ state: { starship } }) =>
  getMaxAllowed(starship, 'crew');

const maxPassengers = ({ state: { starship } }) =>
  getMaxAllowed(starship, 'passengers');

const charactersOnShip = ({ state: { crew, passengers } }) =>
  [...crew, ...passengers].map(({ id }) => id);

const numberOfcharactersOnShip = ({ getters: { charactersOnShip } }) =>
  charactersOnShip.length;

const shipCapacity = ({ getters: { maxCrewMembers, maxPassengers } }) =>
  maxCrewMembers + maxPassengers;

const isCrewFull = ({ state: { crew }, getters: { maxCrewMembers } }) =>
  crew.length >= maxCrewMembers;

const isPassengersFull = ({
  state: { passengers },
  getters: { maxPassengers }
}) =>
  passengers.length >= maxPassengers;

const invertedIndex = ({ state: { crew, passengers } }) => {
  const invertedCrew = {};
  const invertedPassengers = {};

  crew.forEach(({ id }) => {
    invertedCrew[id] = true;
  });

  passengers.forEach(({ id }) => {
    invertedPassengers[id] = true;
  });

  return { crew: invertedCrew, passengers: invertedPassengers };
}

const isShipFull = ({ getters: { numberOfcharactersOnShip, shipCapacity } }) =>
  numberOfcharactersOnShip >= shipCapacity;

const getters = {
  maxCrewMembers,
  maxPassengers,
  charactersOnShip,
  numberOfcharactersOnShip,
  shipCapacity,
  isShipFull,
  isCrewFull,
  isPassengersFull,
  invertedIndex
};

const {
  Provider: QuestProvider,
  useContext: useQuestContext
} = createStore({
  name: 'Quest',
  initialState,
  actions: ACTIONS,
  reducers,
  getters
});

export {
  QuestProvider,
  useQuestContext
};
