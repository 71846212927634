import { createClient, extendClient } from '../client-create';
import transformKeys from '../middleware/transform-keys';

export const jsonConfig = {
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  }
};

export const jsonApiConfig = {
  middleware: [transformKeys]
};

const http = createClient();

export default http;
export const json = extendClient(http, jsonConfig);
export const jsonApi = extendClient(json, jsonApiConfig);
