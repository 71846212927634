const defaultOptions = {
  middleware: arg => arg,
  errorHandler: error => Promise.reject(error)
};

const add = (axiosInstance, { type, name, ...options }) => {
  const { middleware, errorHandler } = {
    ...defaultOptions,
    ...options
  };

  axiosInstance.middleware[type][name] = axiosInstance.interceptors[type].use(
    arg => middleware(arg),
    error => errorHandler(error)
  );
};

export const request = (axiosInstance, options) =>
  add(axiosInstance, { ...options, type: 'request' });

export const response = (axiosInstance, options) =>
  add(axiosInstance, { ...options, type: 'response' });

export const remove = (axiosInstance, { type = 'response', name }) => {
  axiosInstance.interceptors[type].eject(axiosInstance.middleware[type][name]);
  delete axiosInstance.middleware[type][name];
};

export const createMiddleware = (instance, middleware) => {
  middleware.forEach(m => {
    if (!m.name) {
      return new Error('Middleware needs to have a name');
    }

    ['request', 'response'].forEach(type => {
      if (m[type]) {
        add(instance, {
          type,
          name: m.name,
          middleware: m[type],
          ...m[`${type}Error`] ? { errorHandler: m[`${type}Error`] } : {}
        });
      }
    });
  });
};
