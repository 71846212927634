import React, { memo } from 'react';

import Button from 'Components/Button';

import styles from './styles.module.css';

function Pagination({ page, setPage, maxPage }) {
  return (
    <div className={styles.pagination}>
      <Button
        onClick={() => setPage(prev => prev - 1)}
        disabled={page <= 1}
      >
        previous page
      </Button>
      <Button
        onClick={() => setPage(prev => prev + 1)}
        disabled={maxPage !== -1 && page >= maxPage}
      >
        next page
      </Button>
    </div>
  );
}

export default memo(Pagination);
