import React, { memo } from 'react';

import { useQuestContext } from 'Providers/quest';
import Button from 'Components/Button';

import styles from './styles.module.css';

function ActionButtons({ pickerVisible, setPickerVisible, isShipFull }) {
  const { resetQuest } = useQuestContext();

  return (
    <div className={styles.actionButtons}>
      {!pickerVisible && (
        <Button
          className={styles.actionButton}
          onClick={() => setPickerVisible(true)}
        >
          Select crew & passengers
        </Button>
      )}
      {isShipFull && (
        <Button className={styles.actionButton} onClick={() => {
          resetQuest();
          setPickerVisible(false)
        }}>
          Launch quest
        </Button>
      )}
    </div>
  );
}

export default memo(ActionButtons);