// these are "dynamic" to allow potentially switching them out at runtime

export const getApiUrl = () => process.env.REACT_APP_API_URL;

export const getApiVersion = () => process.env.REACT_APP_API_VERSION;

export const getBaseUrl = () => {
  const apiVersion = getApiVersion();
  const hasApiVersion = apiVersion !== '0';

  return `${getApiUrl()}/${hasApiVersion ? `v${apiVersion}/` : ''}`;
};

const ONE_MINUTE = 60 * 1000;
const FIVE_MINUTES = 5 * ONE_MINUTE;
const ONE_HOUR = 60 * ONE_MINUTE;
const TWENTY_FOUR_HOURS = 24 * ONE_HOUR;

export const CACHE_TIME =
  process.env.NODE_ENV === "production" ? TWENTY_FOUR_HOURS : ONE_HOUR;

export const STALE_TIME =
  process.env.NODE_ENV === "production" ? ONE_HOUR : FIVE_MINUTES;