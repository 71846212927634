import React, { memo } from 'react';
import { node } from 'prop-types';

import styles from './styles.module.css';

function MainWithTwoColumns({ children = null }) {
  return (
    <main className={styles.container}>
      {children}
    </main>
  );
}

MainWithTwoColumns.propTypes = {
  children: node
};

export default memo(MainWithTwoColumns);
