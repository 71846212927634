import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';

const transformKeys = (object, transformer) => {
  if (typeof object.data === 'object') {
    object.data = transformer(object.data, { deep: true });
  }

  return object;
};

const request = req => transformKeys(req, snakeCaseKeys);
const response = res => transformKeys(res, camelCaseKeys);

const transformKeysMiddleware = {
  name: 'transformKeys',
  request,
  response
};

export default transformKeysMiddleware;
