import React from 'react';

import { useQuestContext } from 'Providers/quest';
import ship from 'Assets/millennium-falcon.png';

import styles from './styles.module.css';

function Starship() {
  const { state: { starship } } = useQuestContext();

  const { name, model, manufacturer } = starship;

  return (
    <div className={styles.shipContainer}>
      <div className={styles.shipInfo}>
        <div>{name}</div>
        <div>{model}</div>
        <div>{manufacturer}</div>
      </div>
      <img src={ship} className={styles.ship} alt="" />
    </div>
  );
}

export default Starship;
