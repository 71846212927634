const c = document.getElementById('stars');
const ctx = c.getContext('2d');
const circle = 2 * Math.PI;
const canvasWidth = window.innerWidth;
const canvasHeight = window.innerHeight;

c.width = canvasWidth;
c.height = canvasHeight;

class Stars {

  constructor() {
    this.count = 0;
    this.stars = [];
    this.timer = 0;

    this.addStars();
  }

  addStars() {
    const margin = canvasWidth / 30;
    let i = Math.ceil((canvasWidth * canvasHeight) / ((canvasWidth + canvasHeight) * 2));

    this.count = i - 1;

    while(i--) {
      this.stars.push({
        x: Math.floor(Math.random() * (canvasWidth - margin * 2)) + margin,
        y: Math.floor(Math.random() * (canvasHeight - margin * 2)) + margin,
        size: Math.floor(Math.random() * 1) + 1,
        alpha: Math.ceil(Math.random() * 100) / 100,
        tween: false
      });
    }
  }

  update(dt) {
    this.timer += dt;

    // run this roughly every 12 frames
    if(this.timer > 12 * 16) {
      const randomStar = this.stars[Math.round(Math.random() * this.count)];

      if(!randomStar.tween) {
        const { alpha, size } = randomStar;

        randomStar.tween = tween({
          duration: 1000,
          entity: randomStar,
          to: {
            alpha: 1,
            size: randomStar.size * 2
          },
          onEnd: () => {
            randomStar.tween = tween({
              duration: 1000,
              entity: randomStar,
              to: {
                alpha,
                size
              },
              onEnd: () => {
                randomStar.tween = false;
              }
            })
          }
        });
      }

      this.timer = 0;
    }

    this.tween(dt);
  }

  draw() {
    this.stars.forEach(star => {
      ctx.beginPath();
      ctx.fillStyle = 'rgba(255, 255, 255, ' +  star.alpha + ')';
      ctx.arc(star.x, star.y, star.size, 0, circle, false);
      ctx.fill();
    });
  }

  tween(dt) {
    this.stars.forEach(star => {
      if(star.tween) {
        star.tween(dt);
      }
    });
  }
}

class ShootingStar {

  constructor(x, y, alpha, trailScale) {
    this.radius = 4;
    this.startAngle = 0.3 * Math.PI;
    this.endAngle = 1.5 * Math.PI;
    this.xMultiplier = 40;
    this.yMultiplier = 12;
    this.timer = 0;

    const margin = 100;

    this.x = Math.floor(Math.random() * (canvasWidth - margin * 2)) + margin;
    this.y = Math.floor(Math.random() * (canvasHeight - margin * 2)) + margin;
    this.alpha = alpha || 0;
    this.trailScale = trailScale || 1;
  }

  update(dt) {
    if(this.tween) {
      this.tween(dt);
    }

    if(this.moveTween) {
      this.moveTween(dt);
    }

    if(!this.tween && !this.moveTween) {
      this.timer += dt;
      if(this.timer >= 10000 && Math.round(Math.random())) {
        this.reset();
        this.timer = 0;
      }
    }
  }

  draw() {
    ctx.beginPath();
    ctx.fillStyle = 'rgba(255, 255, 255, ' +  this.alpha + ')';
    ctx.arc(this.x, this.y, this.radius, this.startAngle, this.endAngle, false);
    ctx.lineTo(
      this.x + (this.radius * this.xMultiplier * this.trailScale),
      this.y - (this.radius * this.yMultiplier * this.trailScale)
    );
    ctx.fill();
  }

  reset() {
    const margin = 100;

    this.x = Math.floor(Math.random() * (canvasWidth - margin * 2)) + margin;
    this.y = Math.floor(Math.random() * (canvasHeight - margin * 2)) + margin;
    this.alpha = .5;
    this.trailScale = .5;

    this.addTween();
  }

  addTween() {
    this.tween = tween({
      duration: 400,
      entity: this,
      to: {
        alpha: 1,
        trailScale: 1
      },
      onEnd: () => {
        this.tween = tween({
          duration: 400,
          entity: this,
          to: {
            alpha: 0,
            trailScale: .1
          }
        });
      }
    });

    this.moveTween = tween({
      duration: 800,
      entity: this,
      to: {
        x: this.x - 300,
        y: this.y + 100
      },
      tweenKey: 'moveTween'
    });
  }
}

let
  previousTime = performance.now() || Date.now(),
  deltaTime,
  entities = [
    new Stars(),
    new ShootingStar()
  ];

function tick(now) {
  deltaTime = (now - previousTime);

  ctx.clearRect(0, 0, c.width, c.height);

  for(let i = 0, len = entities.length; i < len; i++) {
    entities[i].update(deltaTime);
    entities[i].draw();
  }

  previousTime = now;
  requestAnimationFrame(tick);
}

requestAnimationFrame(tick);

function tween({ duration, entity, to, onEnd, tweenKey = 'tween' }) {
  let
    elapsedTime = 0,
    startValues = {};

  Object.keys(to).forEach(key => {
    if(entity[key]) {
      startValues[key] = entity[key];
    }
  });

  return (dt) => {
    Object.keys(startValues).forEach(key => {
      entity[key] = inOutQuad(
        elapsedTime,
        startValues[key],
        to[key] - startValues[key],
        duration
      );

    });

    elapsedTime += dt;

    if(elapsedTime >= duration) {
      entity[tweenKey] = false;

      if(onEnd) {
        onEnd(startValues);
      }
    }
  };
}

function inOutQuad(t, b, c, d) {
  if((t /= d / 2) < 1) {
    return c / 2 * t * t + b;
  }
  return -c / 2 * ((--t) * (t - 2) - 1) + b;
}