import React from 'react';
import classnames from 'classnames';
import { elementType, bool, string, node } from 'prop-types';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

import styles from './styles.module.css';

function Card({
  component: Component = 'div',
  fullHeight = true,
  className,
  children,
  ...props
}) {
  return (
    <Component
      className={classnames(
        styles.card,
        {
          [styles.fullHeight]: fullHeight
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}

Card.propTypes = {
  component: elementType,
  fullHeight: bool,
  className: string,
  children: node
};

export { Header, Content, Footer };

export default Card;
