export const pick = (object, keys) => {
  const objectWithKeys = {};

  keys.forEach(key => {
    if (typeof object[key] !== 'undefined' && object[key] !== null) {
      objectWithKeys[key] = object[key];
    }
  });

  return objectWithKeys;
};