import React, { memo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  persistQueryClient
} from 'react-query/persistQueryClient-experimental';
import {
  createWebStoragePersistor
} from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { node } from 'prop-types';

import { CACHE_TIME, STALE_TIME } from 'Core/env';
import { QuestProvider } from './quest';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME
    }
  }
});

persistQueryClient({
  queryClient,
  persistor: createWebStoragePersistor({
    storage: window.localStorage
  })
});

function AppProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <QuestProvider>
        {children}
      </QuestProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

AppProviders.propTypes = {
  children: node
};

export default memo(AppProviders);
