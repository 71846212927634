import produce from 'immer';
import { useQueryClient, useQuery } from 'react-query';
import { useState, useEffect } from 'react';

import { fetchPeople } from 'Core/api/people';

const ID_REGEX = /([\d]+)\/?$/;

const addIdsFromUrls = data => produce((data), data => {
  data.results = data.results.map(entity => {
    const [,id] = ID_REGEX.exec(entity.url);
    return { ...entity, id };
  });
});

const getPeople = async config => {
  const { data } = await fetchPeople(config);
  return addIdsFromUrls(data);
};

const usePeople = ({ initialPage, initialSearchTerm = null } = {}) => {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [page, setPage] = useState(initialPage || 1);
  const [maxPage, setMaxPage] = useState(-1);

  const { status, data, error, isFetching, isPreviousData } = useQuery(
    ['people', searchTerm, page],
    () => getPeople({ page, search: searchTerm }),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setPage(1);
    setMaxPage(-1);
  }, [searchTerm, setMaxPage]);

  // prefetch the next page
  useEffect(() => {
    const prefetch = async () => {
      const nextPageIndex = page + 1;
      const queryKey = ['people', searchTerm, nextPageIndex];

      await queryClient.prefetchQuery(queryKey, () =>
        getPeople({ page: nextPageIndex, search: searchTerm })
      );

      const nextPageData = queryClient.getQueryData(queryKey);

      if (maxPage === -1 && !nextPageData?.next) {
        setMaxPage(nextPageIndex);
      }
    }

    if(data?.next && (maxPage === -1 || page + 1 <= maxPage)) {
      prefetch().catch(console.error);
    }
  }, [data, page, queryClient, maxPage, searchTerm]);

  return {
    status,
    data,
    error,
    isFetching,
    isPreviousData,
    page,
    setPage,
    maxPage,
    setSearchTerm
  };
};

export default usePeople;
