import React, { useReducer, useMemo, createContext, useContext } from 'react';

const createReducer = reducers => (state, { type, payload }) => {
  const handler = reducers[type];

  if (!handler) {
    console.error(`Unknown reducer "${type}"`);
    return state;
  }

  return handler(state, payload);
};

const createReducerProvider = ({
  initialState,
  Context: { Provider },
  reducers,
  actions,
  getters: rawGetters
}) => function ReducerProvider({ children }) {
  const [state, dispatch] = useReducer(
    createReducer(reducers),
    initialState
  );

  const getters = useMemo(
    () => {
      const gettersWithState = {};

      Object.keys(rawGetters).forEach(getter => {
        gettersWithState[getter] = rawGetters[getter]({
          state,
          getters: gettersWithState
        });
      });

      return gettersWithState;
    },
    [state]
  );

  const aliases = useMemo(
    () => {
      const prettyActions = {};

      Object.keys(actions).forEach(actionKey => {
        const type = actions[actionKey];
        prettyActions[type] = payload => dispatch({ type, payload });
      });

      return prettyActions;
    },
    []
  );

  const value = useMemo(
    () => ({ ...getters, state, dispatch, ...aliases }),
    [getters, state, dispatch, aliases]
  );

  return <Provider value={value}>{children}</Provider>;
};

export const createStore = ({
  name,
  actions = {},
  initialState = {},
  reducers = {},
  getters = {}
}) => {
  const Context = createContext();

  const Provider = createReducerProvider({
    initialState,
    reducers,
    actions,
    Context,
    getters
  });

  const useStoreContext = () => {
    const ctx = useContext(Context);

    if (!ctx) {
      throw new Error(`😢 forget <${name}Provider>?`);
    }

    return ctx;
  };

  return { Provider, useContext: useStoreContext };
};
