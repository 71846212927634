import React from 'react';

import styles from './styles.module.css';

function EmptyState() {
  return (
    <div className={styles.emptyState}>
      No results found for this search term
    </div>
  );
}

export default EmptyState;
