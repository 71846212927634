import axios from 'axios';

import { createMiddleware } from './middleware-to-axios.js';

export const createClient = (config = {}) => {
  const client = {
    ...axios.create(config),
    all: axios.all,
    spread: axios.spread,
    replay: conf => axios.request(conf),
    config,
    // middleware attached later
    middleware: { request: {}, response: {}},
    CancelToken: axios.CancelToken,
    isCancel: axios.isCancel
  };

  if (config.middleware) {
    createMiddleware(client, config.middleware);
  }

  return client;
};

export const extendClient = ({ config }, extended = {}) => {
  const newConfig = config;

  Object.keys(extended).forEach(key => {
    const oldItem = config[key];
    const newItem = extended[key];

    if (!newItem) {
      newConfig[key] = oldItem;
    }
    else if (Array.isArray(oldItem)) {
      newConfig[key] = [...oldItem, ...newItem];
    }
    else if (typeof oldItem === 'object') {
      newConfig[key] = { ...oldItem, ...newItem };
    }
    else {
      newConfig[key] = newItem;
    }
  });

  return createClient(newConfig);
};
