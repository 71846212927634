import React, { useState, useEffect } from 'react';

import useStarship from 'Hooks/useStarship';
import { useQuestContext } from 'Providers/quest';
import MainWithTwoColumns from 'Layouts/MainWithTwoColumns';
import Squad from 'Components/Squad';
import Starship from 'Components/Starship';
import CharacterPicker from 'Components/CharacterPicker';
import ActionButtons from 'Components/ActionButtons';

// I doubt this is something you'd normally want to do
const MILLENNIUM_FALCON_ID = 10;

function Home() {
  const [pickerVisible, setPickerVisible] = useState(false);

  const {
    status,
    data,
    error
  } = useStarship(MILLENNIUM_FALCON_ID);

  const { setStarship, isShipFull } = useQuestContext();

  useEffect(() => {
    if (data) {
      setStarship(data);
    }
  }, [data, setStarship]);


  if (status === 'loading') {
    return 'loading...';
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
      <MainWithTwoColumns>
        <div>
          <Squad />
          <ActionButtons
            pickerVisible={pickerVisible}
            setPickerVisible={setPickerVisible}
            isShipFull={isShipFull}
          />
        </div>
        {!pickerVisible && <Starship />}
        {pickerVisible && (
          <CharacterPicker onClose={() => setPickerVisible(false)} />
        )}
      </MainWithTwoColumns>
    </>
  );
}

export default Home;
