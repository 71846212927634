import { jsonApi } from 'Core/http/clients';
import { extendClient } from 'Core/http/client-create';
import { getBaseUrl } from 'Core/env';
import { pick } from 'Helpers';

const urlParts = [
  'base',
  'id',
  'resource',
  'resourceId'
];

// @todo provide a nice wrapper around cancelling requests

const joinUrlParts = config =>
  urlParts
    .map(part => config?.[part])
    .filter(Boolean)
    .join('/');

const requestConfig = config => {
  const cancelTokenConfig = config?.cancelToken && {
    cancelToken: config.cancelToken
  };

  return {
    baseURL: getBaseUrl(),
    url: joinUrlParts(config),
    ...cancelTokenConfig,
    params: pick(config, ['page', 'search'])
  };
};

const api = extendClient(jsonApi);

export const fetch = (config = {}) =>
  api.request({ ...requestConfig(config),  method: 'get' });

export const fetchAll = config => fetch({ ...config });

export default api;
