import { useRef, useMemo, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const createParentWithId = (id, mountTo = document.body, type = 'div') => {
  const parent = document.createElement(type);
  parent.setAttribute('id', id);
  mountTo.appendChild(parent);
  return parent;
};
const removeNode = node => node.parentNode.removeChild(node);
const inBrowser = typeof document !== 'undefined';

const usePortal = id => {
  const containerRef = useRef(null);
  const mountTo = useMemo(() => document.body, []);

  // do this before the first paint/render
  useLayoutEffect(() => {
    const parent =
      document.querySelector(`#${id}`) || createParentWithId(id, mountTo);

    parent.appendChild(containerRef.current);

    return () => {
      removeNode(containerRef.current);

      if (!parent.childNodes.length) {
        removeNode(parent);
      }
    };
  }, [containerRef, mountTo, id]);

  if (!containerRef.current) {
    containerRef.current = document.createElement('div');
  }

  return children => createPortal(children, containerRef.current);
};

export default inBrowser ? usePortal : () => null;
