import React, { memo } from 'react';
import classnames from 'classnames';
import { node, string } from 'prop-types';

import styles from './styles.module.css';

function Button({ children = null, variant = null, className, ...props }) {
  return (
    <button
      className={classnames(
        styles.button,
        { [styles[variant]]: variant },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: node,
  className: string
};


export default memo(Button);
