import React, { memo } from 'react';
import classNames from 'classnames';
import { elementType, string, node } from 'prop-types';

import styles from './styles.module.css';

function Content({
  component: Component = 'section',
  className,
  children,
  ...props
}) {
  return (
    <Component className={classNames(styles.content, className)} {...props}>
      {children}
    </Component>
  );
}

Content.propTypes = {
  component: elementType,
  className: string,
  children: node
};


export default memo(Content);
