import React from 'react';

import styles from './styles.module.css';

function EmptySlot() {
  return (
    <div className={styles.emptySlot}>
      empty slot
    </div>
  );
}

export default EmptySlot;
