import React, { memo } from 'react';
import classnames from 'classnames';
import { string, func } from 'prop-types';

import styles from './styles.module.css';

function Input({
  name,
  value,
  onChange,
  placeholder,
  autoComplete = '',
  className = null
}) {
  return (
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      className={classnames(styles.input, className)}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  );
}

Input.propTypes = {
  name: string.isRequired,
  value: string,
  onChange: func,
  placeholder: string,
  autoComplete: string,
  className: string
};

export default memo(Input);
