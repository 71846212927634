import React from 'react';
import { hydrate, render } from 'react-dom';

import Providers from './providers';
import Routes from './routes';
import './legacy/stars';

import './styles/index.css';

const rootElement = document.getElementById('root');

// support for pre-rendering / SSG or SSR
const output = rootElement.hasChildNodes() ? hydrate : render;

output(
  <React.StrictMode>
    <Providers>
      <Routes />
    </Providers>
  </React.StrictMode>,
  rootElement
);
